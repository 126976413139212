import React, { useEffect } from "react";
import {
    CustomCalenderInput,
    CustomChipInput,
    CustomDropDown,
    CustomForm,
    CustomInput,
    CustomInputNumber,
    CustomTextArea,
} from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import PlanContainer from "./PlanContainer";
import { CurrencyOptions, PeriodOptions, YesNoOptions } from "../../../utils/constant";

export default function PlanForm({ type }) {
    const { data, handleChange, loading, onSubmit, setFormType } = PlanContainer();
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <>
            <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
                <CustomInput label="Tag Line" name="tagLine" col={12} required data={data} onChange={handleChange} />
                <CustomInput label="Name" name="name" required data={data} onChange={handleChange} />
                <CustomDropDown label="Period" name="period" options={PeriodOptions} required data={data} onChange={handleChange} />
                <CustomDropDown label="Currency" name="currency" options={CurrencyOptions} required data={data} onChange={handleChange} />
                <CustomInputNumber label="Price" name="price" required data={data} onChange={handleChange} />
                <CustomTextArea label="Description" name="description" required data={data} onChange={handleChange} />
                <CustomInputNumber label="Days" name="days" required data={data} onChange={handleChange} />
                <CustomInputNumber label="Interval" name="interval" required data={data} onChange={handleChange} />
                <CustomDropDown label="Trial" name="isTrial" options={YesNoOptions} data={data} onChange={handleChange} showClear={true} />
                {JSON.stringify(data.isTrial) === "true" ? (
                    <CustomInput label="Trial Days" name="trial" required data={data} onChange={handleChange} />
                ) : JSON.stringify(data.isTrial) === "false" ? (
                    <>
                        <CustomInput required label="Offer Code" name="offer" data={data} onChange={handleChange} />
                        <CustomCalenderInput required label="Offer Expire Date" name="offerExpireAt" data={data} onChange={handleChange} />
                        <CustomInputNumber label="Offer Max Usage" name="maxUsage" data={data} onChange={handleChange} />
                    </>
                ) : null}
                <CustomChipInput data={data} label="Features" name="features" onChange={handleChange} />
                <CustomInput label="IOS Plan Id" name="iosPlanId" required data={data} onChange={handleChange} />
                <CustomInput label="Android Plan Id" name="androidPlanId" required data={data} onChange={handleChange} />
                <CustomInput label="Razorpay Plan Id" name="razorpayPlanId" required data={data} onChange={handleChange} />

                {type !== "VIEW" && <PrimaryButton label="Save Plans" loading={loading} onClick={onSubmit} col={6} />}
            </CustomForm>
        </>
    );
}
