import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { addPlans, editPlans, getPlan } from "../../../redux/actions/plansAction";

export default function ProgramContainer() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();
    let ignore = ["isTrial", "trial", "offer", "offerExpireAt"];

    useEffect(() => {
        if (id) {
            dispatch(
                getPlan(id, (data) => {
                    setData({
                        tagLine: data.tagLine,
                        name: data.name,
                        period: data.period,
                        currency: data.currency,
                        price: data.price,
                        description: data.description,
                        days: data.days,
                        interval: data.interval,
                        isTrial: data.trial ? true : null,
                        trial: data.trial,
                        offer: data.offer,
                        offerExpireAt: data.offerExpireAt ? new Date(data.offerExpireAt) : null,
                        maxUsage: data.maxUsage,
                        features: data.features,
                        iosPlanId: data.iosPlanId,
                        androidPlanId: data.androidPlanId,
                        razorpayPlanId: data.razorpayPlanId,
                    });
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [formType, setFormType] = useState("ADD");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        tagLine: "",
        name: "",
        period: "",
        currency: "",
        price: 0,
        description: "",
        isTrial: null,
        days: 0,
        interval: 0,
        trial: null,
        offer: "",
        offerExpireAt: "",
        maxUsage: null,
        features: [],
        iosPlanId: "",
        androidPlanId: "",
        razorpayPlanId: "",
    });

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            if (name === 'isTrial') {
                setData((prev) => ({ ...prev, formErrors: {} }));

                if (JSON.stringify(value) === 'true') {
                    ignore = ignore.filter(item => item !== "trial");
                    ignore.push("offer", "offerExpireAt");
                    data.offer = "";
                } else if (JSON.stringify(value) === 'false') {
                    data.trial = null;
                    ignore = ignore.filter(item => item !== "offer" && item !== "offerExpireAt");
                    ignore.push("trial");
                } else {
                    data.trial = null;
                    data.offer = "";
                    data.offerExpireAt = null;
                    ignore.push("offer", "offerExpireAt", "trial");
                }
                ignore = [...new Set(ignore)];
                setData((prev) => ({ ...data, [name]: value, formErrors: {} }));

            } else {
                const formErrors = allValidations(name, value, data, ignore);
                setData((prev) => ({ ...prev, [name]: value, formErrors }));
            }

        }
    };

    const onSubmit = () => {
        if (JSON.stringify(data.isTrial) === 'true') {
            ignore = ignore.filter(item => item !== "trial");
            ignore.push("offer", "offerExpireAt");
            data.offer = "";
        } else if (JSON.stringify(data.isTrial) === 'false') {
            ignore = ignore.filter(item => item !== "offer" && item !== "offerExpireAt");
            ignore.push("trial");
        } else {
            ignore.push("offer", "offerExpireAt", "trial");
        }

        ignore = [...new Set(ignore)];

        if (showFormErrors(data, setData, ignore)) {
            if (formType === "ADD") {
                dispatch(addPlans(data, setLoading, history));
            } else if (formType === "EDIT") {
                dispatch(editPlans(id, data, setLoading, history));
            }
        }
    };

    return { data, handleChange, loading, onSubmit, setFormType };
}
